<template>
  <ValidationObserver ref="refAddressForm">
    <ValidationProvider
      name="código postal"
      rules="max:15"
      v-slot="{ errors }"
    >
      <postal-code-address-input
        v-model="form.postal_code"
        :error-messages="errors"
        :label="__('CEP', 'address')"
        :hint="
          $capitalize($tc('model.interface_not_necessary_field_hint', 1))
        "
        @apply="applyFoundAddress"
      />
    </ValidationProvider>
    <ValidationProvider
      name="distrito"
      rules="required"
      v-slot="{ errors }"
    >
      <select-country-area
        v-model="form.country_area"
        :label="__('Estado', 'address')"
        :error-messages="errors"
        name="country_area"
        clearable
        autocomplete="disabled"
      ></select-country-area>
    </ValidationProvider>
    <ValidationProvider
      name="concelho"
      rules="required"
      v-slot="{ errors }"
    >
      <select-city
        v-model="form.city"
        :country_area="form.country_area"
        :label="__('Cidade', 'address')"
        :error-messages="errors"
        name="city"
        clearable
        autocomplete="disabled"
      ></select-city>
    </ValidationProvider>
    <ValidationProvider name="freguesia" v-slot="{ errors }">
      <select-city-area
        v-model="form.city_area"
        :city="form.city"
        :label="__('Bairro', 'address')"
        :error-messages="errors"
        name="city_area"
        clearable
        return-object
        autocomplete="disabled"
      ></select-city-area>
    </ValidationProvider>
    <ValidationProvider
      name="morada"
      rules="required|max:100"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model="form.street"
        :label="__('Rua', 'address')"
        name="street"
        :error-messages="errors"
        autocomplete="disabled"
      />
    </ValidationProvider>
    <ValidationProvider name="number" rules="max:10" v-slot="{ errors }">
      <v-text-field
        :label="__('Número', 'address')"
        name="number"
        type="text"
        :counter="10"
        v-model="form.number"
        :error-messages="errors"
      />
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import SelectCountryArea from "../locals/SelectCountryArea";
import SelectCity from "../locals/SelectCity";
import SelectCityArea from "../locals/SelectCityArea";
import PostalCodeAddressInput from "../locals/PostalCodeAddressInput";
import i18n from '@/mixins/i18n';

export default {
  name: "risk-location-address-form",
  components: { SelectCountryArea, SelectCity, SelectCityArea, PostalCodeAddressInput },
  mixins: [i18n],
  data() {
    return {
      form: {
        postal_code: "",
        country_area: null,
        city: null,
        city_area: null,
        street: null,
        number: null
      },
      streets: [],
      postal_code_loading: false,
      found_postal_addresses: [],
    }
  },
  methods: {
    applyFoundAddress(address) {
      this.form.country_area = address.country_area;
      this.form.city = address.city;
      this.form.city_area = address.city_area;
      this.form.street = address.street;
    },
    resetForm() {
      this.form = {
        ...this.form,
        postal_code: "",
        country_area: null,
        city: null,
        city_area: null,
        street: null,
        number: null
      };

      if (this.$refs.refAddressForm) this.$refs.refAddressForm.reset();
    },
    fillForm(data) {
      this.form = {
        ...this.form,
        ...data
      };
    },
    async validateForm() {
      const valid = await this.$refs.refAddressForm.validate();
      return valid;
    }
  },
  watch: {
    form: {
      handler() {
        this.$emit("input", this.form);
      },
      deep: true
    }
  }
}
</script>

<style>

</style>